
import { defineComponent } from "vue";
import emitter from "@/utils/EventBus";

export default defineComponent({
  name: "PrevButton",
  props: ["loading"],
  setup() {
    const onClick = () => {
      emitter.$emit("question-hint-model", false);
    };

    return {
      onClick,
    };
  },
});
