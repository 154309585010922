import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "fas fa-chevrons-left mr-3 d-none d-xl-inline-block"
}
const _hoisted_2 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createBlock(_component_b_button, {
    class: "btn-theory",
    disabled: _ctx.loading,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_b_spinner, {
              key: 0,
              class: "mr-3",
              style: {"width":"19px","height":"19px"}
            }))
          : (_openBlock(), _createElementBlock("i", _hoisted_1)),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("general.button_previous")), 1)
      ])
    ]),
    _: 1
  }, 8, ["disabled", "onClick"]))
}