
import { Options, Watch } from "vue-property-decorator";
import MultiChoice from "@/components/Pages/TheoryQuestions/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryQuestions/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import MediaQuestionMixin from "@/mixins/TheoryQuestions/MediaQuestionMixin";
import ModalMixin from "@/mixins/ModalMixin.ts";
import { mixins } from "vue-class-component";
import ImageLoadingMixin from "@/mixins/Media/ImageLoadingMixin";
import FscImage from "@/components/FscImage.vue";

@Options({
  components: { FscImage, FreeText, MultiChoice },
})
export default class ImageQuestion extends mixins(QuestionMixin, MediaQuestionMixin, ModalMixin, ImageLoadingMixin) {
  protected countSeen = 0;
  protected imageModal = false;

  private onAnswer(correct: boolean): void {
    this.$emit("onAnswer", {
      correct: correct,
      numberOfTimesPictureWasEnlarged: this.countSeen,
    });
  }

  private showImage(e: PointerEvent) {
    this.countSeen++;
    this.showModal(e, "imageModal");
  }

  private hideImage(e: PointerEvent) {
    this.hideModal(e, "imageModal");
  }

  @Watch("question", { immediate: true, deep: true })
  private onQuestionChange(question: Array<any>): void {
    if (question) {
      this.imgLoadError = false;
    }
  }
}
