import { Options, Vue } from "vue-property-decorator";
import isUndefined from "lodash/isUndefined";

@Options({})
export default class QuestionAnswersMixin extends Vue {
  protected studentEducation!: any;

  protected question: any;

  public answerAction: any;

  protected offset = 0;

  protected questionIsCorrect = undefined;

  private onAnswer(answerData: any): void {
    const data: any = {
      studentEducationId: this.studentEducation,
      theoryQuestionId: this.question.id,
      correct: answerData.correct,
    };

    this.questionIsCorrect = answerData.correct;

    if (!isUndefined(answerData.numberOfTimesVideoWasPlayed)) {
      data.numberOfTimesVideoWasPlayed = answerData.numberOfTimesVideoWasPlayed;
    }
    if (!isUndefined(answerData.numberOfTimesPictureWasEnlarged)) {
      data.numberOfTimesPictureWasEnlarged = answerData.numberOfTimesPictureWasEnlarged;
    }
    this.answerAction({
      resource: "student-question-answers",
      hideSuccessMessage: true,
      data: data,
    });
  }
}
