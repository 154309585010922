import { vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["pointer fa-xl", [_ctx.active ? 'fas' : 'fat', _ctx.iconClass]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandle && _ctx.clickHandle(...args)))
  }, null, 2)), [
    [_vShow, _ctx.question.hint]
  ])
}