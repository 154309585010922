import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["fa-star pointer fa-2xl font-color-curious-blue-500", $props.active ? 'fas' : 'fat'])
  }, null, 2)), [
    [_directive_tooltip, $props.active ? _ctx.$t('general.favourites_remove') : _ctx.$t('general.favourites_add')]
  ])
}