
import { Options, Prop, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import NavigationMixin from "@/mixins/NavigationMixin";
import isArray from "lodash/isArray";
import QuestionAnswersMixin from "@/mixins/TheoryQuestions/QuestionAnswersMixin";

const MostDifficultModule = namespace("theory-questions-most-difficult");
const StudentEducationModule = namespace("student-education");
const TheoryQuestionsModule = namespace("theory-questions");
const StudentQuestionAnswersModule = namespace("student-question-answers");
@Options({
  components: {
    Question,
    DefaultLayout,
  },
})
export default class MostDifficultQuestions extends mixins(NavigationMixin, QuestionAnswersMixin) {
  @Prop()
  public licenceClass!: any;
  @Prop()
  public selectedQuestionId!: any;

  protected studentEducation = 0;

  @TheoryQuestionsModule.Action("findOne")
  public findQuestionAction: any;

  @TheoryQuestionsModule.Action("resetSave")
  public resetTheoryQuestionsQuestion: any;

  @TheoryQuestionsModule.Getter("getIsLoading")
  public loadingQuestion: any;

  @TheoryQuestionsModule.Action("setMarkFavouriteSuccess")
  public setMarkFavouriteSuccessAction: any;

  @StudentQuestionAnswersModule.Action("create")
  public answerAction: any;

  @StudentQuestionAnswersModule.Action("resetSave")
  public resetAnswerQuestion: any;

  @StudentQuestionAnswersModule.Getter("getSuccess")
  public answerSuccess: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public loadingAnswer: any;

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducations: any;

  @MostDifficultModule.Action("findAll")
  public findMostDifficultAction: any;

  @MostDifficultModule.Getter("getDataList")
  public mostDifficult: any;

  @MostDifficultModule.Getter("getIsLoading")
  public mostDifficultLoading: any;

  protected question: any = {};
  private questions = [];
  private showSolutionButton = false;
  private showCorrectAnswers = false;

  get selectedQuestionIndex(): number {
    return isArray(this.questions) ? this.questions.findIndex((q: any) => q.id == this.question.id) : -1;
  }

  setStudentEducation(): void {
    const education: any = this.studentEducations.find((se: any) => se.mainLicenseClass === this.licenceClass);
    this.studentEducation = education ? education.id : this.studentEducations[0].id;
  }

  get loading(): boolean {
    return this.loadingQuestion || this.loadingAnswer || this.mostDifficultLoading;
  }

  private selectQuestion(question: any): void {
    this.showCorrectAnswers = false;
    this.reset();
    this.question = question;
  }

  private goToNext(): void {
    if (this.selectedQuestionIndex >= this.questions.length - 1) {
      return;
    }
    this.selectQuestion(this.questions[this.selectedQuestionIndex + 1]);
  }
  private goToPrev(): void {
    if (this.selectedQuestionIndex <= 0) {
      return;
    }
    this.selectQuestion(this.questions[this.selectedQuestionIndex - 1]);
  }

  private onMarkFavourite(favourite: boolean): void {
    this.loadMostDifficult();
  }

  private loadMostDifficult(): void {
    this.findMostDifficultAction({
      resource: "theory-questions",
      extraPath: "most-difficult",
      hideSuccessMessage: true,
    });
  }

  private showSolutions(): void {
    this.showSolutionButton = false;
    this.showCorrectAnswers = true;
  }

  @Watch("selectedQuestionId", { immediate: true, deep: true })
  private onSelectedQuestionIdChange(selectedQuestionId: any): void {
    if (this.mostDifficult && selectedQuestionId && this.licenceClass) {
      this.questions = this.mostDifficult[this.licenceClass];
      this.question = this.questions.find((mf: any) => mf.id == selectedQuestionId);
    }
  }

  @Watch("mostDifficult", { immediate: true, deep: true })
  private onMostDifficultChange(mostDifficult: any): void {
    if (this.mostDifficult && this.licenceClass) {
      this.questions = this.mostDifficult[this.licenceClass];
      this.question = this.questions.find((mf: any) => mf.id == this.question.id);
    }
  }

  @Watch("studentEducations", { immediate: true, deep: true })
  private onStudentEducationsChange(studentEducations: any): void {
    if (isArray(studentEducations) && studentEducations.length > 0) {
      this.setStudentEducation();
    }
  }

  private reset(): void {
    this.resetTheoryQuestionsQuestion();
    // this.setMarkFavouriteSuccessAction(null);
    // this.resetAnswerQuestion();
  }
}
