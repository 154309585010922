
export default {
  name: "FavoriteButton",
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
  },
};
