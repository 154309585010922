import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "theory-question-answer-free-text" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textSlices, (textSlice, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "d-flex align-items-center",
          key: index
        }, [
          _createTextVNode(_toDisplayString(textSlice) + " ", 1),
          (_ctx.inputFields[index])
            ? (_openBlock(), _createBlock(_component_b_form_input, {
                key: 0,
                class: "mx-1 input-answer",
                modelValue: _ctx.inputFields[index].value,
                "onUpdate:modelValue": ($event: any) => ((_ctx.inputFields[index].value) = $event),
                modelModifiers: { trim: true },
                onInput: ($event: any) => (_ctx.onAnswerChange($event, index)),
                disabled: _ctx.showCorrectAnswers,
                autocomplete: "off"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "disabled"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    (_ctx.showCorrectAnswers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textSlices, (textSlice, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "d-flex align-items-center",
              key: index
            }, [
              _createTextVNode(_toDisplayString(textSlice) + " ", 1),
              (_ctx.answers[index])
                ? (_openBlock(), _createBlock(_component_b_form_input, {
                    key: 0,
                    class: "mx-1",
                    modelValue: _ctx.answers[index].value,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.answers[index].value) = $event),
                    disabled: true,
                    style: {"width":"100px"},
                    state: _ctx.answers[index].correct,
                    onInput: ($event: any) => (_ctx.onAnswerChange($event, index))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "state", "onInput"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}