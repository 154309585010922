import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-curious-blue-500 radius-10 p-4 px-xl-5 py-xl-5 text-white fsc-block th-qs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_collapse = _resolveComponent("b-collapse")!

  return (_openBlock(), _createBlock(_component_b_collapse, {
    id: "question-hint",
    class: "flex-fill",
    onShown: _ctx.onShow,
    onHidden: _ctx.onHide,
    "model-value": _ctx.active
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.questionHint), 1)
    ]),
    _: 1
  }, 8, ["onShown", "onHidden", "model-value"]))
}